import React, { useState, useEffect, forwardRef } from 'react';
import { Card, Form, Button, Modal, Col, Row, ButtonGroup, Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import { useGestionStore } from 'hooks/useGestionStore';
import TablaReact from './TablaReact';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { BsFilePdf } from "react-icons/bs";
import CargandoTabla from './CargandoTabla';
import { useUtilStore } from 'hooks/useUtilStore';
import TablaVacia from './TablaVacia';



const aseguradorasImg = {
  "SURA": '../aseguradoras/sura_color.png',
  "ofcotiza_asegnom": '../aseguradoras/liberty_color.png',
  "REALE": '../aseguradoras/reale_color.png',
  "BCI": '../aseguradoras/bci.png',
  "FID": '../aseguradoras/fid_color.png',
  "LIBERTY": '../aseguradoras/liberty_color.png'
}

const aseguradorasPDF = {

  //Caso de sura se agrega la siguente logica en variables

  /*
    ofcotiza_asegnom
    ofcotiza_taller
    ofcotiza_deducible
    ofcotiza_auto_reemplazo
  */


  //30 Días plus sura
  "SURA_Taller Multimarca_0_Hasta 30 días" : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Estandar_Plus_0.pdf',
  "SURA_Taller Multimarca_3_Hasta 30 días" : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Estandar_Plus_3.pdf',
  "SURA_Taller Multimarca_5_Hasta 30 días" : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Estandar_Plus_5.pdf',
  "SURA_Taller Multimarca_10_Hasta 30 días" : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Estandar_Plus_10.pdf',
  "SURA_Taller Multimarca_20_Hasta 30 días" : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Estandar_Plus_20.pdf',
  "SURA_Taller Marca_0_Hasta 30 días"    : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Premium_Plus_0.pdf',
  "SURA_Taller Marca_3_Hasta 30 días"    : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Premium_Plus_3.pdf',
  "SURA_Taller Marca_5_Hasta 30 días"    : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Premium_Plus_5.pdf',
  "SURA_Taller Marca_10_Hasta 30 días"    : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Premium_Plus_10.pdf',
  "SURA_Taller Marca_20_Hasta 30 días"    : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Premium_Plus_20.pdf',
  "SURA_Taller Marca Garantía_0_Hasta 30 días" : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Garantia_Plus_0.pdf',
  "SURA_Taller Marca Garantía_3_Hasta 30 días" : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Garantia_Plus_3.pdf',
  "SURA_Taller Marca Garantía_5_Hasta 30 días" : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Garantia_Plus_5.pdf',
  "SURA_Taller Marca Garantía_10_Hasta 30 días" : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Garantia_Plus_10.pdf',
  "SURA_Taller Marca Garantía_20_Hasta 30 días" : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Garantia_Plus_20.pdf',


  //15 Días sura
  "SURA_Taller Multimarca_0_Hasta 15 días" : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Estandar_0.pdf',
  "SURA_Taller Multimarca_3_Hasta 15 días" : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Estandar_3.pdf',
  "SURA_Taller Multimarca_5_Hasta 15 días" : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Estandar_5.pdf',
  "SURA_Taller Multimarca_10_Hasta 15 días" : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Estandar_10.pdf',
  "SURA_Taller Multimarca_20_Hasta 15 días" : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Estandar_20.pdf',
  "SURA_Taller Marca_0_Hasta 15 días"    : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Premium_0.pdf',
  "SURA_Taller Marca_3_Hasta 15 días"    : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Premium_3.pdf',
  "SURA_Taller Marca_5_Hasta 15 días"    : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Premium_5.pdf',
  "SURA_Taller Marca_10_Hasta 15 días"    : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Premium_10.pdf',
  "SURA_Taller Marca_20_Hasta 15 días"    : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Premium_20.pdf',
  "SURA_Taller Marca Garantía_0_Hasta 15 días" : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Garantia_0.pdf',
  "SURA_Taller Marca Garantía_3_Hasta 15 días" : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Garantia_3.pdf',
  "SURA_Taller Marca Garantía_5_Hasta 15 días" : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Garantia_5.pdf',
  "SURA_Taller Marca Garantía_10_Hasta 15 días" : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Garantia_10.pdf',
  "SURA_Taller Marca Garantía_20_Hasta 15 días" : 'https://estoyseguro.cl/includes/terminos_auto_sura_plan_Garantia_20.pdf',



  "REALE_Taller_Aseguradora" : 'https://estoyseguro.cl/includes/terminos_auto_reale_plan_Taller_Aseguradora.pdf',
  "REALE_Taller_Marca" : 'https://estoyseguro.cl/includes/terminos_auto_reale_plan_Taller_Marca.pdf',
  "REALE_Exclusividad_de_Taller" : 'https://estoyseguro.cl/includes/terminos_auto_reale_plan_Exclusividad_de_Taller.pdf',
  "Auto Preferente - T. Marca EstoySeguro_FID_Taller Marca" : 'https://estoyseguro.cl/cinco/includes/terminos_auto_fid_plan_Auto_Preferente_-_T._Marca_EstoySeguro.pdf',
  "Auto Preferente - T. MultiMarca EstoySeguro_FID_Taller Multimarca" : 'https://estoyseguro.cl/cinco/includes/terminos_auto_fid_plan_Auto_Preferente_-_T._MultiMarca_EstoySeguro.pdf',
  "Auto Conveniente - EstoySeguro_FID_Taller Multimarca" : 'https://estoyseguro.cl/cinco/includes/terminos_auto_fid_plan_Auto_Conveniente_-_EstoySeguro.pdf',
  "LIBERTY_Plan Estandar" : 'https://estoyseguro.cl/includes/terminos_auto_liberty_plan_Plan_Estandar.pdf',
  "LIBERTY_Plan Plus" : 'https://estoyseguro.cl/includes/terminos_auto_liberty_plan_Plan_Plus.pdf',
  "LIBERTY_Plan Premium" : 'https://estoyseguro.cl/includes/terminos_auto_liberty_plan_Plan_Premium.pdf',
}

const unificarNombres = {
  'Taller marca': 'Taller Marca',
  'Taller Marca': 'Taller Marca',
  'Taller de Marca': 'Taller Marca',
  'Taller Garantía': 'Taller Marca Garantía',
  'Taller de Garantía': 'Taller Marca Garantía',
  'Exclusividad de Taller': 'Exclusividad Taller',
  'libre eleccion': 'Exclusividad Taller',
  'Taller Multimarca': 'Taller Multimarca',
  'Taller MultiMarca': 'Taller Multimarca',
  'Taller Aseguradora': 'Taller Multimarca'
}

const TablaCotizaciones = ({ nombre, ejecutivos, bitacora_status, bitacora_tipo_cotizante, UFDeldia }) => {
  //set momenent hora chile
  moment.tz.setDefault('America/Santiago');

  const { user } = useSelector(state => state.login);


  const [cargar, setCargar] = useState(true)

  const [filterCotiz, setFilterCotiz] = useState('');
  const [cotiza_auto, setCotiza_auto] = useState('');

  const [bitacora, setBitacora] = useState()
  const [obtenerOfertas, setObtenerOfertas] = useState()

  const [tipoCotizante, setTipoCotizante] = useState(''); // Estado para el tipo de cotizante
  const [status, setStatus] = useState(''); // Estado para el estado/status
  const [comentario, setComentario] = useState(''); // Estado para el comentario

  const [startDate, setStartDate] = useState('');
  const [modalShow2, setModalShow2] = useState(false);

  const [obtenerAuto, setObtenerAuto] = useState([])
  const [ejercutivosData, setEjercutivosData] = useState()
  const [bitacora_statusData, setBitacora_statusData] = useState()
  const [bitacora_tipo_cotizanteData, setBitacora_tipo_cotizanteData] = useState()

  const [dataCambio, setDataCambio] = useState([])

  const { dataInicio, dataFinal } = useSelector(state => state.datapicker);

  const [deducibleSLT, setDeducibleSLT] = useState('')
  const [aseguradoraSLT, setAseguradoraSLT] = useState('')
  const [tallerSLT, setTallerSLT] = useState('')

  const [uniqueAsegnomArray1, setUniqueAsegnomArray1] = useState()
  const [uniqueAsegnomArray2, setUniqueAsegnomArray2] = useState()
  const [uniqueAsegnomArray3, setUniqueAsegnomArray3] = useState()


  const [selectModalEjec, setSelectModalEjec] = useState({})
  const [actualizarBit, setActualizarBit] = useState(false)
  const [originalOfertas, setOriginalOfertas] = useState([]);

  const [selectedRadioValue, setSelectedRadioValue] = useState(null);
  // Callback function to receive selected radio button value
  const handleRadioChange = (value) => {
    setSelectedRadioValue(value);
  };

  const {
    startObterner_bitacora,
    startObtener_ofertas,
    startObtenerAuto,
    startGuarfarBitacora,
    startObtenerCotizaciones,
    startObtenerDatos,
    startModificarEjecutivo,
    startGeneralVariacionFV
    // Otros métodos del useGestionStore
  } = useGestionStore();



 

  useEffect(async () => {
    // Función para cargar los datos iniciales
    const fetchData = async () => {
      try {
        // Obtener datos de ejecutivos, status y tipo de cotizante
        const [ejecutivosDataInit, Bitacora_status, Bitacora_tipo_cotizante] = await Promise.all([
          ejecutivos,
          bitacora_status,
          bitacora_tipo_cotizante
        ]);

        setEjercutivosData(ejecutivosDataInit);
        setBitacora_statusData(Bitacora_status);
        setBitacora_tipo_cotizanteData(Bitacora_tipo_cotizante);

        // Obtener y actualizar las cotizaciones iniciales
    
        let nuevasCotizaciones = await startObtenerCotizaciones(dataInicio, dataFinal,user.rol === '2' || user.rol === '3'?user.name:'');
        
        if(nuevasCotizaciones){
          setDataCambio(nuevasCotizaciones.data);
        }else{
          setDataCambio([]);
          setCargar(false)
        }


        



      } catch (error) {
        console.error('Error al cargar los datos iniciales:', error);
      }
    };

    fetchData();

    
  }, [dataInicio, dataFinal]);

 

  useEffect(async () => {

    let ejercutivosDataInit = await ejecutivos;
    if (ejercutivosDataInit) {
      setEjercutivosData(ejercutivosData);
    } else {
      setEjercutivosData([]);
    }

    let Bitacora_status = await bitacora_status;
    if (Bitacora_status) {
      setBitacora_statusData(Bitacora_status);
    } else {
      setBitacora_statusData([]);
    }

    let Bitacora_tipo_cotizante = await bitacora_tipo_cotizante;
    if (Bitacora_tipo_cotizante) {
      setBitacora_tipo_cotizanteData(Bitacora_tipo_cotizante);
    } else {
      setBitacora_tipo_cotizanteData([]);
    }


    let nuevasCotizaciones = await startObtenerCotizaciones(dataInicio, dataFinal,user.rol === '2' || user.rol === '3'?user.name:'');
  
            
    if(nuevasCotizaciones){
      setDataCambio(nuevasCotizaciones.data);
    }else{
      setDataCambio([]);
      setCargar(false)
    }


    



  }, [actualizarBit]);

  
  const format = (number) => {
    if (number !== undefined) {
        const parts = number.toString().split('.');
        const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        let decimalPart = parts[1] ? ',' + parts[1] : '';

        if (decimalPart.length > 1) {
            // Si la longitud del decimal es mayor a 1, redondea a un decimal
            decimalPart = ',' + parseFloat('0.' + parts[1]).toFixed(1).split('.')[1];
        }

        return integerPart + decimalPart;
    }
}



  const columns2 = React.useMemo(
    () => [
      {
        Header: 'Aseguradora',
        accessor: 'ofcotiza_asegnom',
        Cell: ({ row }) => (
          <Row>
            <Col className='text-center contenedorImgOfertas' >
              <img src={aseguradorasImg[row.original.ofcotiza_asegnom]} className='imgOfertas' />
            </Col>
          </Row>

        ),
      },

      {
        Header: 'Deducible',
        accessor: 'ofcotiza_deducible',
      },
      {
        Header: 'Taller',
        accessor: 'ofcotiza_taller',
      },
      {
        Header: 'Total Bruto',
        accessor: 'ofcotiza_valor_total',
        Cell: ({ row }) => (
          <OverlayTrigger
          placement="left-end"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip" >
          {'$'+format(row.original.ofcotiza_valor_total*UFDeldia)}
        </Tooltip>}
        >
          <p>{row.original.ofcotiza_valor_total}</p>
        </OverlayTrigger>
              

    
        ),
      },
      {
        Header: 'Cuotas',
        accessor: 'ofcotiza_cuotas_max',
      },
      {
        Header: 'Mensual UF',
        accessor: 'ofcotiza_valor_cuota',
        Cell: ({ row }) => (
          <OverlayTrigger
          placement="left-end"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip" >
          {'$'+format(row.original.ofcotiza_valor_cuota*UFDeldia)}
        </Tooltip>}
        >
          <p>{row.original.ofcotiza_valor_cuota}</p>
        </OverlayTrigger>
              

    
        ),
      },
/*       {
        Header: 'Mensual UF',
        accessor: 'ofcotiza_valor_cuota',
      }, */
/*           {
        Header: 'Mensual $',
        accessor: 'ofcotiza_valor_cuota',
      }, */
      {
        Header: 'Auto Remplazo',
        accessor: 'ofcotiza_auto_reemplazo',
      },
      {
        Header: 'PDF',
        Cell: ({ row }) => (
          <Row className='text-center'>
            <a href={row.original.ofcotiza_asegnom=="SURA"?aseguradorasPDF[row.original.ofcotiza_asegnom+"_"+row.original.ofcotiza_taller+"_"+row.original.ofcotiza_deducible+"_"+row.original.ofcotiza_auto_reemplazo]:row.original.ofcotiza_asegnom=="FID"?aseguradorasPDF[row.original.ofcotiza_nombre_produc+"_"+row.original.ofcotiza_asegnom+"_"+row.original.ofcotiza_taller]:aseguradorasPDF[row.original.ofcotiza_asegnom+"_"+row.original.ofcotiza_plan_nombre]} target='_blank'><BsFilePdf className="iconoTablaPdf" /></a>
          </Row>

        ),
      },
      {
        Header: 'Seleccione',
        Cell: ({ row }) => (
          <div className="d-grid ">
            <Form.Check

              type={'radio'}
              label={`Coberturas`}
              id={`Radio${row.original.ofcotiza_id}`}
            />
          </div>

        ),
      },
      // ... Add more columns as needed
    ],
    []
  );
  
  const columns = React.useMemo(
    () => [
      {
        Header: 'Cotización',
        accessor: 'cotiza_id',
      },
      {
        Header: 'Rut',
        accessor: 'cliente_clie_rut',
      },
      {
        Header: 'Email',
        accessor: 'clie_email',
      },
      {
        Header: 'Celular',
        accessor: 'clie_celular',
      },
      {
        Header: 'Origen',
        accessor: 'origen_nombre',
      },
      {
        Header: 'Fecha',
        accessor: 'cotiza_fecha',
        Cell: ({ value }) => {

          const fechaHoraOriginal = moment(value,'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
          const DIFF_HORA_GLOBAL = parseInt(process.env.DIFF_HORA_GLOBAL, 10);
          const fechaHoraRestada = fechaHoraOriginal.subtract(DIFF_HORA_GLOBAL, 'hours');
          const fechaHoraFormateada = fechaHoraRestada.format('DD/MM/YYYY HH:mm');
       

          return fechaHoraFormateada
        }, // Formatea la fecha usando moment
      },
      {
        Header: 'Ejecutivo',
        accessor: 'cotiza_responsable',
      },
      {
        Header: 'Acción',
        Cell: ({ row }) => (
          <div className="d-grid">
            <Button variant={row.original.tiene_emision == 1 ? 'success' : 'primary'} className='me-2 mb-1' size="sm" onClick={() => buscarCotiz(row.original.cotiza_id, row.original.cotiza_auto)}>Detalle</Button>
            {
              row.original.tiene_pendiente == 1 ? <Button variant='light' className='me-2 mb-1' size="sm" >Pendiente</Button> : ''
            }

          </div>

        ),
      },
      // ... Add more columns as needed
    ],
    []
  );

  const columns3 = React.useMemo(
    () => [
      {
        Header: 'N°',
        accessor: (row, index) => index + 1,
      },
      {
        Header: 'Iteración',
        accessor: 'bitacora_fecha',
        Cell: ({ value }) => moment(value).format('DD/MM/YYYY HH:mm:ss'), // Formatea la fecha usando moment
      },

      {
        Header: 'Reagendamiento',
        accessor: 'bitacora_reagendado',
        Cell: ({ value }) => value ? moment(value).format('DD/MM/YYYY HH:mm:ss') : '', // Formatea la fecha usando moment
      },
      {
        Header: 'Tipo de Cotizante',
        accessor: 'tipo_descripcion',
      },
      {
        Header: 'Status',
        accessor: 'status_descripcion',
      },
      {
        Header: 'Comentario',
        accessor: 'bitacora_observacion',
      },

      // ... Add more columns as needed
    ],
    []
  );

  const buscarCotiz = async(cotiza_id,cotiza_auto) =>{


    setModalShow2(true)
    let ncotiz = cotiza_id

    let bitacoraInit = await startObterner_bitacora(ncotiz);
    if (bitacoraInit) {
      setBitacora(bitacoraInit.data);
    } else {
      setBitacora([]);
    }

    let obtenerOfertasInit = await startObtener_ofertas(ncotiz);
    if (obtenerOfertasInit) {

 
      let newArray = obtenerOfertasInit.data.map(item => {

        return {
          ...item,
          ofcotiza_deducible: item.ofcotiza_deducible === "" ? "0" : item.ofcotiza_deducible,
          ofcotiza_taller: unificarNombres[item.ofcotiza_taller]
        };
      })

          // Parsear la fecha en formato ISO 8601
    let fechaOferta = moment(obtenerOfertasInit.data[0].ofcotiza_fecha);
    let fechaActual = moment();

    let diferencia = fechaActual.diff(fechaOferta, 'days');
    if(diferencia>=13){
      document.getElementById('btnEmitirCotiz').disabled = true;
    }else{
      document.getElementById('btnEmitirCotiz').disabled = false;
    }

      setObtenerOfertas(newArray);
      buscarSelects(ncotiz);
    } else {
      setObtenerOfertas([]);

    }

    let obtenerAutoInit = await startObtenerAuto(cotiza_auto);
    if (obtenerAutoInit) {
      setObtenerAuto(obtenerAutoInit.data);
    } else {
      setObtenerAuto([]);
    }


    let nuevasCotizaciones = await startObtenerDatos(ncotiz);

    await Promise.all([bitacoraInit,
      obtenerOfertasInit,
      obtenerAutoInit,
      nuevasCotizaciones]);
    let initFiltro = nuevasCotizaciones.data.find(buscar => buscar.cotiza_id == ncotiz)
    setFilterCotiz(initFiltro);

    /*     if(Object.keys(initFiltro).length > 0){
          setModalShow2(true)
        } */



  }

  const enviarURlRecotizar = () => {
    const url = `https://estoyseguro.cl/seguro-automotriz-vendedor.php?var1=&
                  val1=${encodeURIComponent(`cot_detalles_es=${filterCotiz.cotiza_id}`)}&
                  cot_detalles_patente=${encodeURIComponent(filterCotiz.cotiza_patente)}&
                  cot_detalles_rut=${encodeURIComponent(filterCotiz.cliente_clie_rut)}&
                  cot_detalles_origen=${encodeURIComponent(filterCotiz.origen_nombre)}&
                  cot_detalles_auto_motoid=&
                  cot_detalles_autoid=${encodeURIComponent(filterCotiz.cotiza_auto)}&
                  cot_detalles_responsable=${encodeURIComponent(filterCotiz.cotiza_responsable)}&
                  cot_detalles_cotiz_cambio=126154&
                  cot_detalles_sexo=${encodeURIComponent(filterCotiz.clie_sexo)}&
                  cot_detalles_email=${encodeURIComponent(filterCotiz.clie_email)}&
                  cot_detalles_fono=${encodeURIComponent(filterCotiz.clie_telefono)}&
                  cot_detalles_Celular=${encodeURIComponent(filterCotiz.clie_celular)}&
                  cot_detalles_fechanac=${encodeURIComponent(filterCotiz.clie_fechnac)}&
                  cot_detalles_estado=${encodeURIComponent(filterCotiz.cotiza_estado)}&
                  cot_detalles_comuna=${encodeURIComponent(filterCotiz.clie_comuna)}&
                  cot_detalles_region=${encodeURIComponent(filterCotiz.clie_region)}&
                  cot_detalles_marca=${encodeURIComponent(filterCotiz.cotiza_marca)}&
                  cot_detalles_modelo=${encodeURIComponent(filterCotiz.cotiza_modelo)}&
                  cot_detalles_anno=${encodeURIComponent(filterCotiz.cotiza_anno)}&
                  cot_detalles_kms=${encodeURIComponent(filterCotiz.cotiza_km_anio)}&
                  cot_detalles_combustible=${encodeURIComponent(obtenerAuto[0] ? obtenerAuto[0].auto_combustible : '')}&
                  cot_detalles_reagendamiento=&
                  cot_detalles_comentario=&
                  filtro_aseg=Seleccione&
                  filtro_deducible=Seleccione&
                  filtro_Taller=null`;

    const urlSinEspacios = url.replace(/\s/g, ''); // Esto elimina todos los espacios en blanco de la URL

    // Puedes usar urlSinEspacios en tu aplicación React


    window.open(urlSinEspacios, "_blank");
  }
  const enviarURlEspecial = () => {
    const varBtoa = window.btoa(filterCotiz.cotiza_id)

    const url = `https://estoyseguro.cl/seguro-automotriz-emision.php?var1=${varBtoa}`;


    window.open(url, "_blank");
  }

  const enviarURlRenovar = () => {
    const url = `https://estoyseguro.cl/seguro-automotriz-renovar.php?var1=&
                val1=${encodeURIComponent(`cot_detalles_es=${filterCotiz.cotiza_id}`)}&
                cot_detalles_patente=${encodeURIComponent(filterCotiz.cotiza_patente)}&
                cot_detalles_rut=${encodeURIComponent(filterCotiz.cliente_clie_rut)}&
                cot_detalles_origen=${encodeURIComponent(filterCotiz.origen_nombre)}&
                cot_detalles_auto_motoid=&
                cot_detalles_autoid=${encodeURIComponent(filterCotiz.cotiza_auto)}&
                cot_detalles_responsable=${encodeURIComponent(filterCotiz.cotiza_responsable)}&
                cot_detalles_cotiz_cambio=126154&
                cot_detalles_sexo=${encodeURIComponent(filterCotiz.clie_sexo)}&
                cot_detalles_email=${encodeURIComponent(filterCotiz.clie_email)}&
                cot_detalles_fono=${encodeURIComponent(filterCotiz.clie_telefono)}&
                cot_detalles_Celular=${encodeURIComponent(filterCotiz.clie_celular)}&
                cot_detalles_fechanac=${encodeURIComponent(filterCotiz.clie_fechnac)}&
                cot_detalles_estado=${encodeURIComponent(filterCotiz.cotiza_estado)}&
                cot_detalles_comuna=${encodeURIComponent(filterCotiz.clie_comuna)}&
                cot_detalles_region=${encodeURIComponent(filterCotiz.clie_region)}&
                cot_detalles_marca=${encodeURIComponent(filterCotiz.cotiza_marca)}&
                cot_detalles_modelo=${encodeURIComponent(filterCotiz.cotiza_modelo)}&
                cot_detalles_anno=${encodeURIComponent(filterCotiz.cotiza_anno)}&
                cot_detalles_kms=${encodeURIComponent(filterCotiz.cotiza_km_anio)}&
                cot_detalles_combustible=${encodeURIComponent(obtenerAuto[0] ? obtenerAuto[0].auto_combustible : '')}&
                cot_detalles_reagendamiento=&
                cot_detalles_comentario=&
                filtro_aseg=Seleccione&
                filtro_deducible=Seleccione&
                filtro_Taller=null`;

    const urlSinEspacios = url.replace(/\s/g, ''); // Esto elimina todos los espacios en blanco de la URL

    // Puedes usar urlSinEspacios en tu aplicación React


    window.open(urlSinEspacios, "_blank");
  }

  const enviarURlEmitir = () => {
    if (selectedRadioValue) {
      const url = `https://estoyseguro.cl/seguro-automotriz-cinco/?var1=${selectedRadioValue}&
      val1=${encodeURIComponent(`cot_detalles_es=${filterCotiz.cotiza_id}`)}&
      cot_detalles_patente=${encodeURIComponent(filterCotiz.cotiza_patente)}&
      cot_detalles_rut=${encodeURIComponent(filterCotiz.cliente_clie_rut)}&
      cot_detalles_origen=${encodeURIComponent(filterCotiz.origen_nombre)}&
      cot_detalles_auto_motoid=&
      cot_detalles_autoid=${encodeURIComponent(filterCotiz.cotiza_auto)}&
      cot_detalles_responsable=${encodeURIComponent(filterCotiz.cotiza_responsable)}&
      cot_detalles_cotiz_cambio=126154&
      cot_detalles_sexo=${encodeURIComponent(filterCotiz.clie_sexo)}&
      cot_detalles_email=${encodeURIComponent(filterCotiz.clie_email)}&
      cot_detalles_fono=${encodeURIComponent(filterCotiz.clie_telefono)}&
      cot_detalles_Celular=${encodeURIComponent(filterCotiz.clie_celular)}&
      cot_detalles_fechanac=${encodeURIComponent(filterCotiz.clie_fechnac)}&
      cot_detalles_estado=${encodeURIComponent(filterCotiz.cotiza_estado)}&
      cot_detalles_comuna=${encodeURIComponent(filterCotiz.clie_comuna)}&
      cot_detalles_region=${encodeURIComponent(filterCotiz.clie_region)}&
      cot_detalles_marca=${encodeURIComponent(filterCotiz.cotiza_marca)}&
      cot_detalles_modelo=${encodeURIComponent(filterCotiz.cotiza_modelo)}&
      cot_detalles_anno=${encodeURIComponent(filterCotiz.cotiza_anno)}&
      cot_detalles_kms=${encodeURIComponent(filterCotiz.cotiza_km_anio)}&
      cot_detalles_combustible=${encodeURIComponent(obtenerAuto[0] ? obtenerAuto[0].auto_combustible : '')}&
      cot_detalles_reagendamiento=&
      cot_detalles_comentario=&
      filtro_aseg=Seleccione&
      filtro_deducible=Seleccione&
      filtro_Taller=null`;

      const urlSinEspacios = url.replace(/\s/g, ''); // Esto elimina todos los espacios en blanco de la URL

      // Puedes usar urlSinEspacios en tu aplicación React

      window.open(urlSinEspacios, "_blank");
    } else {

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Debes seleccionar una oferta',
        footer: 'No se puede emitir sin oferta'
      })

    }

  }

  const guardarBitacora = async(bitacora_cotiza_id) =>{
    if(tipoCotizante != "" && status != ""){
      let guardando = await startGuarfarBitacora(bitacora_cotiza_id,
        startDate,
        tipoCotizante,
        status,
        comentario?comentario:'sin comentarios')
        await Promise.all([guardando]);
        let bitacoraInit = await startObterner_bitacora(bitacora_cotiza_id)
      
        setBitacora(bitacoraInit.data)
        setActualizarBit(!actualizarBit)

    }else{
      Swal.fire("Debe completar todos los campos","","info")
    }

  }
  const buscarSelects = async(ncotiz) =>{
    // Crear un Set para almacenar los valores únicos
    const uniqueAsegnomSet1 = new Set();
    const uniqueAsegnomSet2 = new Set();
    const uniqueAsegnomSet3 = new Set();


    let obtenerOfertasInit = await startObtener_ofertas(ncotiz);
    if (obtenerOfertasInit) {
      let newArray = obtenerOfertasInit.data.map(item => {

        return {
          ...item,
          ofcotiza_deducible: item.ofcotiza_deducible === "" ? "0" : item.ofcotiza_deducible,
          ofcotiza_taller: unificarNombres[item.ofcotiza_taller]
        };
      })
      setObtenerOfertas(newArray);
      // Almacenar las ofertas originales
      setOriginalOfertas(newArray);

    } else {
      setObtenerOfertas([]);

    }
    obtenerOfertasInit?.data.map((item) => {
      uniqueAsegnomSet1.add(item.ofcotiza_asegnom);
      // Modificar el valor de 'item.ofcotiza_deducible' y agregarlo a otro conjunto
      uniqueAsegnomSet2.add(item.ofcotiza_deducible === "" ? "0" : item.ofcotiza_deducible);
      uniqueAsegnomSet3.add(unificarNombres[item.ofcotiza_taller]);
    });


    setUniqueAsegnomArray1(Array.from(uniqueAsegnomSet1));
    setUniqueAsegnomArray2(Array.from(uniqueAsegnomSet2));
    setUniqueAsegnomArray3(Array.from(uniqueAsegnomSet3));


  }

  const modificarEjecutivoChange = async (cotiza_id) => {

    let respuestaUpdate = await startModificarEjecutivo(cotiza_id, selectModalEjec.selectedId, selectModalEjec.selectedName)

    if (respuestaUpdate.data.affectedRows > 0) {
      let nuevasCotizaciones = await startObtenerDatos(cotiza_id);
      let initFiltro = nuevasCotizaciones.data.find(buscar => buscar.cotiza_id == cotiza_id)
      setFilterCotiz(initFiltro);
      setActualizarBit(!actualizarBit)
    }
  }
  const selectChange = (event) => {
    const selectedId = event.target.value;
    const selectedName = event.target.options[event.target.selectedIndex].text;
    setSelectModalEjec({ selectedId, selectedName })
  }

  const changeDeducible = (event) => {
    const selectedId = event.target.value;
    setDeducibleSLT(selectedId);

    // Copia las ofertas originales en un nuevo array
    let filteredOffers = [...originalOfertas];

    // Aplica el filtro de deducible si está seleccionado
    if (selectedId !== '') {
      filteredOffers = filteredOffers.filter((item) => item.ofcotiza_deducible === selectedId);
    }

    // Aplica el filtro de aseguradora si está seleccionado
    if (aseguradoraSLT !== '') {
      filteredOffers = filteredOffers.filter((item) => item.ofcotiza_asegnom === aseguradoraSLT);
    }

    // Aplica el filtro de taller si está seleccionado
    if (tallerSLT !== '') {

      filteredOffers = filteredOffers.filter((item) => item.ofcotiza_taller === tallerSLT);

    }

    // Actualiza las ofertas filtradas en el estado
    setObtenerOfertas(filteredOffers);
  };

  const changeAseguradora = (event) => {
    const selectedId = event.target.value;
    setAseguradoraSLT(selectedId);

    // Copia las ofertas originales en un nuevo array
    let filteredOffers = [...originalOfertas];

    // Aplica el filtro de aseguradora si está seleccionado
    if (selectedId !== '') {
      filteredOffers = filteredOffers.filter((item) => item.ofcotiza_asegnom === selectedId);
    }

    // Aplica el filtro de deducible si está seleccionado
    if (deducibleSLT !== '') {
      filteredOffers = filteredOffers.filter((item) => item.ofcotiza_deducible === deducibleSLT);
    }

    // Aplica el filtro de taller si está seleccionado
    if (tallerSLT !== '') {
      filteredOffers = filteredOffers.filter((item) => item.ofcotiza_taller === tallerSLT);
    }

    // Actualiza las ofertas filtradas en el estado
    setObtenerOfertas(filteredOffers);
  };

  const changeTaller = (event) => {
    const selectedId = event.target.value;
    setTallerSLT(selectedId);

    // Copia las ofertas originales en un nuevo array
    let filteredOffers = [...originalOfertas];

    // Aplica el filtro de taller si está seleccionado
    if (selectedId !== '') {

      if (selectedId == "Taller Marca Garantía") {
        filteredOffers = filteredOffers.filter((item) => item.ofcotiza_taller === selectedId || item.ofcotiza_asegnom === "REALE" && item.ofcotiza_taller === "Taller Multimarca");

      } else {
        filteredOffers = filteredOffers.filter((item) => item.ofcotiza_taller === selectedId);
      }
    }

    // Aplica el filtro de deducible si está seleccionado
    if (deducibleSLT !== '') {
      filteredOffers = filteredOffers.filter((item) => item.ofcotiza_deducible === deducibleSLT);
    }

    // Aplica el filtro de aseguradora si está seleccionado
    if (aseguradoraSLT !== '') {
      filteredOffers = filteredOffers.filter((item) => item.ofcotiza_asegnom === aseguradoraSLT);
    }

    // Actualiza las ofertas filtradas en el estado
    setObtenerOfertas(filteredOffers);
  };

  const handleClose = () => {
    setModalShow2(false)
    setFilterCotiz([])
    setSelectedRadioValue(null)
    setDeducibleSLT('')
    setAseguradoraSLT('')
    setTallerSLT('')
    document.getElementById('btnEmitirCotiz').disabled = false;
  }

  const GreetingsDateInput = forwardRef(({ value, onClick }, ref) => (
    <div className="position-relative">
      <Form.Control

        ref={ref}
        onClick={onClick}
        value={value}
        className="ps-4"
        onChange={(e) => {

        }}
      />
      <FontAwesomeIcon
        icon="calendar-alt"
        className="text-primary position-absolute top-50 translate-middle-y ms-2"
      />
    </div>
  ));


  return (
    <>
    <Card className="h-100">
      <Card.Header className="pb-0">
        <h6 className="mb-5 mt-2">{nombre}</h6>
      </Card.Header>
      {
         Object.keys(dataCambio).length>0?<TablaReact  btnCotizar={true} Ncolum={20}  columns={columns} data={dataCambio} />:cargar?<CargandoTabla columns={columns} />:<TablaVacia columns={columns} />
      }
      
    </Card>

      <Modal
        show={modalShow2}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">DETALLES DE LA COTIZACIÓN</Modal.Title>
        </Modal.Header>
        <Modal.Body key={1}>
          <Row className="p-2 mb-1">
            {
              Object.keys(filterCotiz).length > 0 ? (
                <>
                  <Col xs={4} className="p-2 ">
                    <Form.Group className="mb-3 cajasInfo" controlId="exampleForm.ControlInput1">
                      <Form.Label>Cotización ES</Form.Label>
                      <p> {filterCotiz.cotiza_id} </p>
                    </Form.Group>
                  </Col>




                  <Col xs={4} className="p-2 ">
                    <Form.Group className="mb-3 cajasInfo" controlId="exampleForm.ControlInput1">
                      <Form.Label>Origen</Form.Label>
                      <p> {filterCotiz.origen_nombre} </p>
                    </Form.Group>
                  </Col>

                  <Col xs={4} className="p-2 ">
                    <Form.Group className="mb-3 cajasInfo" controlId="exampleForm.ControlInput1">
                      <Form.Label>Responsable</Form.Label>
                      <p>{filterCotiz.cotiza_responsable} </p>
                    </Form.Group>
                  </Col>
                  {
                    user.rol !== '3' ? (<Row className=""><Col xs={3} className="p-2 ">
                      <Form.Group className="mb-3 cajasInfo" controlId="exampleForm.ControlInput1">
                        <Form.Label>Cambiar Responsable a:</Form.Label>
                        <Form.Select defaultValue={filterCotiz.cotiza_ejec_id}
                          aria-label="Default select example"
                          onChange={selectChange}
                        >
                          <option>Selecione</option>
                          {
                            ejecutivos ? ejecutivos.map(item => (
                              <option key={item.ejec_id} value={item.ejec_id}>{item.ejec_nombre}</option>
                            )) : ''
                          }
                        </Form.Select>
                      </Form.Group>
                    </Col>
                      <Col xs={6} className="p-2 ">
                        <Button onClick={() => modificarEjecutivoChange(filterCotiz.cotiza_id)}>Guardar</Button>
                      </Col></Row>) : ('')
                  }


                  <h3 className='mt-5 mb-5'>DATOS PERSONALES</h3>
                  <Col xs={4} className="p-2 ">
                    <Form.Group className="mb-3 cajasInfo" controlId="exampleForm.ControlInput1">
                      <Form.Label>RUT</Form.Label>
                      <p> {filterCotiz.cliente_clie_rut} </p>
                    </Form.Group>
                  </Col>
                  <Col xs={4} className="p-2 ">
                    <Form.Group className="mb-3 cajasInfo" controlId="exampleForm.ControlInput1">
                      <Form.Label>Nombre</Form.Label>
                      <p> {filterCotiz.clie_nombre + " "+filterCotiz.clie_paterno} </p>
                    </Form.Group>
                  </Col>
                  <Col xs={4} className="p-2 ">
                    <Form.Group className="mb-3 cajasInfo" controlId="exampleForm.ControlInput1">
                      <Form.Label>Teléfono</Form.Label>
                      <p>{filterCotiz.clie_telefono} </p>
                    </Form.Group>
                  </Col>
                  
                  <Col xs={4} className="p-2 ">
                    <Form.Group className="mb-3 cajasInfo" controlId="exampleForm.ControlInput1">
                      <Form.Label>E-mail</Form.Label>
                      <p> {filterCotiz.clie_email} </p>
                    </Form.Group>
                  </Col>
                  <Col xs={4} className="p-2 ">
                    <Form.Group className="mb-3 cajasInfo" controlId="exampleForm.ControlInput1">
                      <Form.Label>Comuna</Form.Label>
                      <p>{filterCotiz.clie_comuna} </p>
                    </Form.Group>
                  </Col>
                  
                  <Col xs={4} className="p-2 ">
                    <Form.Group className="mb-3 cajasInfo" controlId="exampleForm.ControlInput1">
                      <Form.Label>Fecha de Nacimiento</Form.Label>
                      <p>{filterCotiz.clie_fechnac} </p>
                    </Form.Group>
                  </Col>


 

                  <h3 className='mt-5 mb-5'>DATOS VEHICULO</h3>

                  <Col xs={2} className="p-2 ">
                    <Form.Group className="mb-3 cajasInfo" controlId="exampleForm.ControlInput1">
                      <Form.Label>Patente</Form.Label>
                      <p> {filterCotiz.cotiza_patente} </p>
                    </Form.Group>
                  </Col>

                  <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 cajasInfo" controlId="exampleForm.ControlInput1">
                      <Form.Label>Marca</Form.Label>
                      <p>{filterCotiz.marca_nombre} </p>
                    </Form.Group>
                  </Col>

                  <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 cajasInfo" controlId="exampleForm.ControlInput1">
                      <Form.Label>Modelo</Form.Label>
                      <p>{filterCotiz.modelo_nombre} </p>
                    </Form.Group>
                  </Col>

                  <Col xs={2} className="p-2 ">
                    <Form.Group className="mb-3 cajasInfo" controlId="exampleForm.ControlInput1">
                      <Form.Label>Año</Form.Label>
                      <p>{filterCotiz.cotiza_anno} </p>
                    </Form.Group>
                  </Col>


                  <Col xs={2} className="p-2 ">
                    <Form.Group className="mb-3 cajasInfo" controlId="exampleForm.ControlInput1">
                      <Form.Label>Combustible</Form.Label>
                      <p>{obtenerAuto[0] ? obtenerAuto[0].combustible_nombre : ''} </p>
                    </Form.Group>
                  </Col>
                </>
              ) : (
                <>



















                  <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 skeleton  pb-3">
                      <Form.Label>Cotización ES</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 skeleton  pb-3">
                      <Form.Label>Embajador</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 skeleton  pb-3">
                      <Form.Label>Patente</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 skeleton  pb-3">
                      <Form.Label>RUT</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 skeleton  pb-3">
                      <Form.Label>Origen</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 skeleton  pb-3">
                      <Form.Label>AUTO ID</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 skeleton  pb-3">
                      <Form.Label>Responsable</Form.Label>
                    </Form.Group>
                  </Col>
                  <h3 className='mt-5 mb-5'>DATOS PERSONALES</h3>
                  <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 skeleton  pb-3">
                      <Form.Label>Sexo</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 skeleton  pb-3">
                      <Form.Label>E-mail</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 skeleton  pb-3">
                      <Form.Label>Teléfono</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 skeleton  pb-3">
                      <Form.Label>Celular</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 skeleton  pb-3">
                      <Form.Label>Fecha de Nacimiento</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 skeleton  pb-3">
                      <Form.Label>Estado</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 skeleton  pb-3">
                      <Form.Label>Región</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 skeleton  pb-3">
                      <Form.Label>Comuna</Form.Label>
                    </Form.Group>
                  </Col>
                  <h3 className='mt-5 mb-5'>DATOS VEHICULO</h3>
                  <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 skeleton  pb-3">
                      <Form.Label>Marca</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 skeleton  pb-3">
                      <Form.Label>Año</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 skeleton  pb-3">
                      <Form.Label>Kilómetros a recorrer</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xs={3} className="p-2 ">
                    <Form.Group className="mb-3 skeleton  pb-3">
                      <Form.Label>Tipo de combustible</Form.Label>
                    </Form.Group>
                  </Col>
                </>)
            }
            <Row>
              <h3 className='mt-5 mb-5'>GESTIÓN CALL CENTER</h3>

              <Col xs={3} className="p-2 ">
                <Form.Group className="mb-3 cajasInfo" controlId="exampleForm.Fecha">
                  <label className="form-label" >Reagendamiento</label>
                  <DatePicker
                    selected={startDate} // Pass the selected date
                    onChange={setStartDate} // Handle date selection
                    startDate={startDate}
       
                    timeIntervals={5}
                    dateFormat="MM/dd/yyyy h:mm aa"
                    showTimeSelect
                    fixedHeight
                    customInput={<GreetingsDateInput />}
                  />
                </Form.Group>
              </Col>

        <Col xs={3} className="p-2 ">
            <Form.Group className="mb-3 cajasInfo" controlId="exampleForm.cotizaTipo">
              <Form.Label>Tipo de Cotizante</Form.Label>
              <Form.Select 
               value={tipoCotizante}
               onChange={(e) => setTipoCotizante(e.target.value)}
               aria-label="Default select example">
                <option value={''}>Selecione</option>
                {
                  
                  bitacora_tipo_cotizante?bitacora_tipo_cotizante.map((item,key)=>(
                    <option key={key} value={item.tipo_id}>{item.tipo_descripcion}</option>
                  )):<option>Cargando...</option>
                }
              </Form.Select>
            </Form.Group>
        </Col>
                
        <Col xs={3} className="p-2 ">
            <Form.Group className="mb-3 cajasInfo" controlId="exampleForm.cotizaEstado">
              <Form.Label>Estado</Form.Label>
              <Form.Select 
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  aria-label="Default select example">
                <option value={''}>Selecione</option>
                
                {
                  
                  bitacora_status?bitacora_status.map((item,key)=>(
                    <option key={key} value={item.status_id}>{item.status_descripcion}</option>
                  )):<option>Cargando...</option>
                }
              </Form.Select>
            </Form.Group>
        </Col>
        <Col xs={3} className="p-2 ">
            <Form.Group className="mb-3 cajasInfo" controlId="exampleForm.Comentario">
              <Form.Label>Comentario</Form.Label>
              <textarea 
                        value={comentario}
                        onChange={(e) => setComentario(e.target.value)}
              className="form-control" id="exampleFormControlTextarea1" rows="1"></textarea>
            </Form.Group>
        </Col>
        <Col xs={12} className="p-2 ">
            <Form.Group controlId="exampleForm.ControlInput1">
                <Button onClick={() => guardarBitacora(filterCotiz.cotiza_id)}>Guardar</Button>
            </Form.Group>
            
        </Col>
      </Row>

            {
              bitacora ? <TablaReact size={'xl'} Ncolum={3} data={bitacora} columns={columns3} /> : <h5>No tiene Bitacora Creada</h5>

            }



            <Row className='rowColor'>
              <Col xs={4}>
                <Form.Label>Aseguradora</Form.Label>
                <Form.Select aria-label="Default select example" value={aseguradoraSLT} onChange={changeAseguradora}>
                  <option value="">Todos</option>
                  {uniqueAsegnomArray1 ? uniqueAsegnomArray1.map((item, key) => (
                    <option value={item} key={key} >{item}</option>
                  )) : ''}
                </Form.Select>
              </Col>
              <Col xs={4}>
                <Form.Label>Deducible</Form.Label>
                <Form.Select aria-label="Default select example" value={deducibleSLT} onChange={changeDeducible}>
                  <option value="">Todos</option>
                  {uniqueAsegnomArray2 ? uniqueAsegnomArray2.map((item, key) => (
                    <option key={key} value={item === '' || isNaN(item) ? 0 : item} >{item}</option>
                  )) : ''}
                </Form.Select>
              </Col>
              <Col xs={4}>
                <Form.Label>Taller</Form.Label>
                <Form.Select aria-label="Default select example" value={tallerSLT} onChange={changeTaller}>

                  <option value="">Todos</option>
                  {uniqueAsegnomArray3 ? uniqueAsegnomArray3.map((item, key) => (
                    <option key={key} value={item} >{item}</option>
                  )) : ''}
                </Form.Select>
              </Col>
            </Row>
            <br /><hr /><br />

       {
        obtenerOfertas && UFDeldia?<TablaReact Ncolum={10} data={obtenerOfertas} columns={columns2} onRadioChange={handleRadioChange}/>:<h5>No tiene Ofertas</h5>
      }





          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs={12}>
              <ButtonGroup aria-label="Basic example">

                <Button variant="warning" onClick={() => enviarURlRecotizar()}>Recotizar</Button>

                <Button variant="light" onClick={() => enviarURlEspecial()}>Especial</Button>
                <Button variant="info" onClick={() => enviarURlRenovar()}>Renovar</Button>
                <Button id="btnEmitirCotiz" variant="primary" onClick={() => enviarURlEmitir()}>Emitir</Button>


              </ButtonGroup>

            </Col>
          </Row>


        </Modal.Footer>
      </Modal>

    </>

  );
};

export default TablaCotizaciones;
